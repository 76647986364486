import { createSlice } from "@reduxjs/toolkit";
export const managementChartsSlice = createSlice({
  name: "managementCharts",
  initialState: {
    operatorsResponse: {
      pagination: {},
      data: [],
    },
    clientsResponse: {
      pagination: {},
      data: [],
    },
    teamsResponse: {
      pagination: {},
      data: [],
    },
    efficiencyResponse: {
      pagination: {},
      data: [],
    },
    filters: {
      operator_ids: [],
    },
    exportTaskId: null,
  },
  reducers: {
    setOperatorsResponse: (state, action) => {
      state.operatorsResponse = action.payload;
    },
    setClientsResponse: (state, action) => {
      state.clientsResponse = action.payload;
    },
    setTeamsResponse: (state, action) => {
      state.teamsResponse = action.payload;
    },
    setEfficiencyResponse: (state, action) => {
      state.efficiencyResponse = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setExportTaskId: (state, action) => {
      state.exportTaskId = action.payload;
    },
    clearExportTaskId: (state) => {
      state.exportTaskId = null;
    },
  },
});
export const {
  setOperatorsResponse,
  setClientsResponse,
  setTeamsResponse,
  setEfficiencyResponse,
  setFilters,
  setExportTaskId,
  clearExportTaskId,
} = managementChartsSlice.actions;

export default managementChartsSlice.reducer;
