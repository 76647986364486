import { createSlice } from "@reduxjs/toolkit";

export const subtaskPageSlice = createSlice({
  name: "subtaskpage",
  initialState: {
    subtask: null,
    activities: [],
    sortOption: "created_at",
  },
  reducers: {
    setSubtask: (state, action) => {
      state.subtask = action.payload;
    },
    setActivities: (state, action) => {
      state.activities = action.payload;
    },
    updateActivity: (state, action) => {
      const updatedActivity = action.payload;
      const activityIndex = state.activities.findIndex(
        (activity) => activity.id === updatedActivity.id
      );

      if (activityIndex === -1) {
        // se non contiene attachments, utilizza quello che hai in memoria per questa attivita
        state.activities.push(updatedActivity);
      } else {
        state.activities[activityIndex] = {
          ...state.activities[activityIndex],
          ...updatedActivity,
          attachments:
            updatedActivity.attachments ||
            state.activities[activityIndex].attachments,
        };
      }
    },
    setAssignedOperators: (state, action) => {
      state.subtask.operators = action.payload;
    },
    clearAssignedOperators: (state) => {
      state.subtask.operators = [];
    },
    clearAll: (state) => {
      state.subtask = null;
      state.activities = [];
    },
    // updateActivity: (state, action) => {
    //   const updatedActivity = action.payload;
    //   state.activities = state.activities.map((activity) =>
    //     activity.id === updatedActivity.id ? updatedActivity : activity
    //   );
    // },
    setSortOption: (state, action) => {
      state.sortOption = action.payload;
    },
    setAttachmentsToActivity: (state, action) => {
      if (state.activities) {
        state.activities = state.activities.map((activity) => {
          if (activity.id === action.payload.activityId) {
            return {
              ...activity,
              attachments: action.payload.attachments,
            };
          }
          return activity;
        });
      }
    },
    addAttachmentsToActivity: (state, action) => {
      if (state.activities) {
        state.activities = state.activities.map((activity) => {
          if (activity.id === action.payload.activityId) {
            return {
              ...activity,
              attachments: activity.attachments.concat(
                action.payload.attachments
              ),
            };
          }
          return activity;
        });
      }
    },
    removeAttachmentFromActivity: (state, action) => {
      // remove attachment from activity
      if (state.activities) {
        state.activities = state.activities.map((activity) => {
          if (activity.id === action.payload.id) {
            return {
              ...activity,
              attachments: activity.attachments.filter(
                (attachment) => attachment.id !== action.payload.attachment.id
              ),
            };
          }
          return activity;
        });
      }
    },
  },
});

export const {
  setSubtask,
  setActivities,
  setAssignedOperators,
  clearAssignedOperators,
  clearAll,
  updateActivity,
  setSortOption,
  setAttachmentsToActivity,
  addAttachmentsToActivity,
  removeAttachmentFromActivity,
} = subtaskPageSlice.actions;
export default subtaskPageSlice.reducer;
