import React from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import AnimationWrapper from "./AnimationWrapper.jsx";
import AnnouncementModal from "./AnnouncementModal.jsx";

// Redux
import { useSelector } from "react-redux";
/**
 * A function that renders the protected route based on the authentication status.
 *
 * @param {object} children - The child elements to be rendered within the protected route.
 * @return {JSX.Element} The rendered child elements if authenticated, otherwise a redirection to unauthorized page.
 */
export default function ProtectedRoute({ children }) {
  const navigate = useNavigate();
  const [isAuthorized, setIsAuthorized] = React.useState(false);
  const userType =
    JSON.parse(localStorage.getItem("user_type")) ||
    useSelector((state) => state.userAuth.userType);

  const subdomain = JSON.parse(localStorage.getItem("subdomain"));

  const checkSubdomain = () => {
    if (!window.location.hostname.startsWith(subdomain)) {
      window.location.href = `${window.location.protocol}//${subdomain}.${window.location.host}/`;
    }
  };

  /**
   * Checks authentication and authorization, manages login data transfer between domains.
   */
  const checkAuth = () => {
    const authCheck = JSON.parse(localStorage.getItem("access"));
    const operatorCheck = JSON.parse(localStorage.getItem("operator_id"));

    // Check if user is authenticated
    if (!authCheck) {
      navigate("/login");
      return;
    }

    // Check if user is authorized
    const userIsAuthorized = (userType) => {
      const authorizedUserTypes = new Set(["AD", "SU", "CA", "CO"]);
      return authorizedUserTypes.has(userType);
    };

    if (!userIsAuthorized(userType)) {
      navigate("/403");
      return;
    }

    // If user has not completed operator
    if (!operatorCheck) {
      const userId = jwtDecode(localStorage.getItem("access")).user_id;
      navigate(`/complete-registration/${userId}`);
      return;
    }

    setIsAuthorized(true);
  };

  React.useEffect(() => {
    checkAuth();
    checkSubdomain();
    window.addEventListener("storage", checkAuth);

    return () => {
      window.removeEventListener("storage", checkAuth);
    };
  }, [navigate]);

  if (!isAuthorized) {
    return null;
  }

  return (
    <AnimationWrapper>
      {children}
      {/* <AnnouncementModal /> */}
    </AnimationWrapper>
  );
}
