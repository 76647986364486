import { createSlice } from "@reduxjs/toolkit";

export const storyPointsSlice = createSlice({
  name: "chronomaster",
  initialState: {
    globalTime: null,
    storyPoints: [],
    subtaskTotalTime: {},
    subtaskTimeList: {},
    subtasksBulkTimeList: {
      data: [],
      pagination: { next: null, previous: null, count: 0 },
    },
    companiesTimerTable: [],
  },
  reducers: {
    setGlobalTime: (state, action) => {
      state.globalTime = action.payload;
    },
    clearGlobalTime: (state) => {
      state.globalTime = null;
    },
    addGlobalTime: (state, action) => {
      const bulkTime = action.payload; // assuming bulkTime is a Day.js object
      const bulkHours = bulkTime.hour();
      const bulkMinutes = bulkTime.minute();

      if (state.globalTime) {
        // Parse existing globalTime
        const [hours, minutes] = state.globalTime
          .split("h")
          .map((part) => parseInt(part.trim().replace("m", ""), 10));

        const totalMinutes =
          hours * 60 + minutes + (bulkHours * 60 + bulkMinutes);
        const newHours = Math.floor(totalMinutes / 60);
        const newMinutes = totalMinutes % 60;

        // Ensure minutes are two digits
        state.globalTime = `${newHours}h ${newMinutes
          .toString()
          .padStart(2, "0")}m`;
      } else {
        // If no globalTime is set, just use bulkTime
        state.globalTime = `${bulkHours}h ${bulkMinutes
          .toString()
          .padStart(2, "0")}m`;
      }
    },

    setStoryPoints: (state, action) => {
      state.storyPoints = action.payload;
    },
    clearStoryPoints: (state) => {
      state.storyPoints = [];
    },
    setSubtaskTotalTime: (state, action) => {
      const { subtaskId, time } = action.payload;
      state.subtaskTotalTime[subtaskId] = time;
    },
    clearSubtaskTotalTime: (state) => {
      state.subtaskTotalTime = {};
    },
    updateSubtaskTotalTime: (state, action) => {
      const { subtaskId, time } = action.payload;
      if (!state.subtaskTotalTime[subtaskId]) {
        state.subtaskTotalTime[subtaskId] = time;
      } else {
        state.subtaskTotalTime[subtaskId] = {
          hours: state.subtaskTotalTime[subtaskId].hours + time.hours,
          minutes: state.subtaskTotalTime[subtaskId].minutes + time.minutes,
        };
      }
    },
    setSubtaskTimeList: (state, action) => {
      const { subtaskId, timeEntries } = action.payload;
      state.subtaskTimeList[subtaskId] = timeEntries;
    },
    // Bulk Creation
    setSubtasksBulkTimeList: (state, action) => {
      const { data, pagination } = action.payload;
      state.subtasksBulkTimeList = { data, pagination };
    },
    clearSubtasksBulkTimeList: (state) => {
      state.subtasksBulkTimeList = {
        data: [],
        pagination: { next: null, previous: null, count: 0 },
      }
    },
    setCompaniesTimerTable: (state, action) => {
      state.companiesTimerTable = action.payload;
    }
  },
});

export const {
  setGlobalTime,
  clearGlobalTime,
  addGlobalTime,
  setStoryPoints,
  clearStoryPoints,
  setSubtaskTotalTime,
  clearSubtaskTotalTime,
  updateSubtaskTotalTime,
  setSubtaskTimeList,
  setSubtasksBulkTimeList,
  clearSubtasksBulkTimeList,
  setCompaniesTimerTable,
} = storyPointsSlice.actions;

export default storyPointsSlice.reducer;
