import { createSlice } from "@reduxjs/toolkit";

export const taskpageSlice = createSlice({
  name: "taskpage",
  initialState: {
    task: null,
    subtasks: [],
    deletedSubtaskIds: [],
    nextPage: null,
    prevPage: null,
    count: null,
    extras: null,
  },
  reducers: {
    setSubtasksOfTaskResult: (state, action) => {
      state.subtasks = action.payload.data;
      state.next = action.payload.pagination.next;
      state.previous = action.payload.pagination.previous;
      state.count = action.payload.pagination.count;
      state.extras = action.payload.extras;
    },
    addSubtasks: (state, action) => {
      state.subtasks = state.subtasks.concat(action.payload);
    },
    updateSubtask: (state, action) => {
      state.subtasks = state.subtasks.map((subtask) => {
        if (subtask.id === action.payload.id) {
          return action.payload;
        }
        return subtask;
      });
    },
    setTask: (state, action) => {
      state.task = action.payload;
    },
    setSubtasks: (state, action) => {
      state.subtasks = action.payload;
    },
    setAssignedOperators: (state, action) => {
      state.task.operators = action.payload;
    },
    clearAssignedOperators: (state) => {
      state.task.operators = [];
    },
    clearAll: (state) => {
      state.task = null;
      state.subtasks = [];
      state.deletedSubtaskIds = [];
      state.nextPage = null;
      state.prevPage = null;
      state.count = null;
    },
    setAssignedOperatorsOfSpecificSubTask: (state, action) => {
      state.subtasks = state.subtasks.map((subtask) => {
        if (subtask.id === action.payload.id) {
          return {
            ...subtask,
            operators: action.payload.operators,
          };
        }
        return subtask;
      });
    },
    addDeletedSubtaskIds: (state, action) => {
      state.deletedSubtaskIds = [...state.deletedSubtaskIds, action.payload];
    },
  },
});

export const {
  setSubtasksOfTaskResult,
  addSubtasks,
  updateSubtask,
  setTask,
  setSubtasks,
  setAssignedOperators,
  clearAssignedOperators,
  clearAll,
  setAssignedOperatorsOfSpecificSubTask,
  addDeletedSubtaskIds,
} = taskpageSlice.actions;
export default taskpageSlice.reducer;
