import { createSlice } from "@reduxjs/toolkit";

export const announcementsSlice = createSlice({
  name: "announcements",
  initialState: {
    announcements: [],
    latestAnnouncement: null,
    loading: false,
  },
  reducers: {
    setAnnouncements: (state, action) => {
      state.announcements = action.payload;
    },
    setLatestAnnouncement: (state, action) => {
      state.latestAnnouncement = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const { setAnnouncements, setLatestAnnouncement, setLoading } =
  announcementsSlice.actions;

export default announcementsSlice.reducer;
