import { createSlice } from "@reduxjs/toolkit";

export const companyChartsSlice = createSlice({
  name: "companyCharts",
  initialState: {
    companyTotalTime: 0,
    operatorsResponse: {
      pagination: {},
      data: [],
    },
    servicesResponse: {
      pagination: {},
      data: [],
    },
    tasksResponse: {
      pagination: {},
      data: [],
    },
    trendResponse: {
      pagination: {},
      data: [],
    },
    filters: {
      operator_ids: [],
    },
    exportTaskId: null,
  },
  reducers: {
    setCompanyTotalTime: (state, action) => {
      state.companyTotalTime = action.payload;
    },
    setOperatorsResponse: (state, action) => {
      state.operatorsResponse = action.payload;
    },
    setServicesResponse: (state, action) => {
      state.servicesResponse = action.payload;
    },
    setTasksResponse: (state, action) => {
      state.tasksResponse = action.payload;
    },
    setTrendResponse: (state, action) => {
      state.trendResponse = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setExportTaskId: (state, action) => {
      state.exportTaskId = action.payload;
    },
    clearExportTaskId: (state) => {
      state.exportTaskId = null;
    },
  },
});

export const {
  setCompanyTotalTime,
  setOperatorsResponse,
  setServicesResponse,
  setTasksResponse,
  setTrendResponse,
  setFilters,
  setExportTaskId,
  clearExportTaskId,
} = companyChartsSlice.actions;

export default companyChartsSlice.reducer;
